<template>
    <ValidationProvider
      name="images"
      :rules="draggableData.length == 0 ? 'required' : '' "
      v-slot="{ errors }"
    >
      <draggable 
        class="draggableContainer"
        v-model="draggableData" 
        @end="listChangeHandle($event)" 
        :animation="80"
        draggable=".draggableItem"
      >
        <div v-for="(file,index) in draggableData" :key="index" class="draggableItem" :draggable="draggableData.length == 1 ? false : true">
          <upload-single-image 
            :fileObject="file"
            :setFile="updateFile"
            :removeAsset="handleRemove"
            :indexOfFile="index"
            :productId="selectedProductId ? selectedProductId : '' "
            :removeSingleFile="singleFileRemoveHandle"
            @preview-event="handleImagePreview"
            :moduleType="moduleType"
          />
        </div>
        <div slot="footer" class="addMoreDraggableItem" draggable="false">
          <input 
            type="file" 
            name="file" 
            multiple="multiple" 
            class="uploadInput" 
            @change="uploadHandle($event)" 
            :accept=" filesType == 'images' ? 'image/png,image/jpg,image/jpeg,image/webp' : filesType == 'images' ? 'video/quicktime' : '' ">
          <i class="el-icon-plus"></i>
        </div>
      </draggable>
      <span class="text-danger">{{ errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
import draggable from 'vuedraggable'
import {mapGetters} from 'vuex';
import { MessageBox, Message, Loading } from "element-ui";
import _ from "lodash";
import uploadSingleImage from "./uploadSingleImage.vue";
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      url: process.env.VUE_APP_BASE_URL + '/uploadProductImages',
      draggableData:[],
      headers: {},
    }
  },
  props:{
    setFiles:{
      type: Function
    },
    draggableValues:{
      type: Array,
      required: true,
      description: 'Files'
    },
    defaultFiles: {
      type: Array,
      default: ()=>[],
      description: ''
    },
    filesType:{
      type:String,
      required: true,
      description:'type of files'
    },
    selectedProductId:{
      description:'product id'
    },
    onReorder:{
      type: Function
    },
    onRemove:{
      type: Function
    },
    disabled:{
      type:Boolean,
      default: false
    },
    moduleType:{
      type:String,
      required: true,
      description: 'module type',
      default: 'products'
    },
  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
    }),
  },
  watch:{
    'draggableValues':{
      handler:function(value){

        this.draggableData = _.cloneDeep(value);

      },deep:true
    }
  },
  components:{
    draggable,
    uploadSingleImage,
  },
  methods:{
    listChangeHandle(e){

      if(JSON.stringify(this.draggableData) !== JSON.stringify(this.draggableValues)){

        this.assignOrder(true);

        this.onReorder();

      }

    },
    uploadHandle(e){

      if(e.target.files.length > 10){

        this.$notify({
          type: "error",
          title: "Alert",
          message: "You are limited to uploading a maximum of 10 images at a time.",
        }); 

        return

      }

      let validFiles = [];
      
      e.target.files.forEach(file => {

        if(file.name.length > 50){

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file name must not exceed 50 characters.",
          }); 

        }else{

          if((this.filesType == 'images') && (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png')){

            validFiles.push(file);

          }else{

            if(file.type.includes('image/')){

              let uploadedFileType = file.type.slice(6);
              
              this.$notify({
                type: "error",
                title: "Alert",
                message: `${uploadedFileType.toUpperCase()} is not a valid file type`,
              });

            }else{

              this.$notify({
                type: "error",
                title: "Alert",
                message: "Uploaded file must be an image",
              }); 

            }

          }

        }

      });

      for(let i = 0;i < validFiles.length; i++){

        this.draggableData.push(validFiles[i]);

      }

      this.setUpdatedData();

    },
    updateFile(file){

      let uuid = uuidv4();

      let fileObj = {
        id: file.id,
        uid: uuid,
        name: file.name,
        status: 'success',
        url: file.url,
        raw:{
          originalFileName: file.orgName
        }
      }

      let index = this.draggableData.findIndex((dataItem) => dataItem instanceof File && dataItem.name == file.orgName);

      if(index != -1){

        this.draggableData.splice(index,0);

        this.draggableData[index] = fileObj;

        this.assignOrder(false);

        this.setUpdatedData();

      }

    },
    setUpdatedData(){

      this.setFiles(this.draggableData);
      
    },
    handleRemove(file){

      MessageBox.confirm(
        "Are you sure? image will be deleted permanently", 
        "Warning", 
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {

        if(this.moduleType == 'products'){

          let loader = Loading.service({
            fullscreen: true,
            text: 'The product image is being removed. Please Wait!',
          });

          let formData = new FormData();

          formData.append('image',file.name);
          formData.append('stored',1);

          try{
            let res = await this.$axios.post('/removeSingleProductImage',formData);
            if(res.data.status_code == "10091"){

              let product = res.data.product;

              product.seo.keywords = product.seo.keywords.map((key) => {
                return key.keyword;
              });

              product.upsells = product.upsells.map((upsell) =>{

                let obj = {
                  id: upsell.linked_product.id,
                  name:upsell.linked_product.name
                }

                return obj

              });

              this.$notify({
                type: 'success',
                title: 'Success',
                message: "The image has been successfully removed.",
              });

              this.setRemoveData(file,product);

              this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

            }
          }catch(error){

            if(error.response){

              if(error.response.data.error.includes('deleted or is no longer available')){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                });

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }
            
            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            loader.close();

          }

        }else if(this.moduleType == 'product-bundles'){

          let loader = Loading.service({
            fullscreen: true,
            text: 'The product bundle image is being removed. Please Wait!',
          });

          let formData = new FormData();

          formData.append('image',file.name);
          formData.append('form_type','update');

          try{
            let res = await this.$axios.post('/product-bundle/image/remove/single',formData);
            if(res.data.status_code == "1766"){
            
              res.data.product_bundle.bundle_items = res.data.product_bundle.bundle_items.map((listedItem) => {

                listedItem.item_details = JSON.parse(listedItem.item_details);

                return listedItem;

              });

              res.data.product_bundle.tags = res.data.product_bundle.tags.map((tag) => {

                return tag.name

              });

              res.data.product_bundle.keywords = res.data.product_bundle.keywords.map((keyword) => {

                return keyword.name

              });

              let productBundle = res.data.product_bundle;

              this.$notify({
                type: 'success',
                title: 'Success',
                message: "The image has been successfully removed.",
              });

              this.setRemoveData(file,productBundle);

              this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);
            
            }
          }catch(error){

            if(error.response){

              if(error.response.data.error.includes('deleted or is no longer available')){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                });

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            loader.close();

          }

        }

      }).catch(()=>{})

    },
    singleFileRemoveHandle(file,product){
        
      let index = this.draggableData.findIndex((dataItem)=> dataItem.name == file.name);

      if(index != -1){

        this.draggableData.splice(index,1);

        this.assignOrder(false);

        this.setUpdatedData();

      }else if(this.draggableData.some((file) =>  file instanceof File)){
          
        this.draggableData.pop();

        this.assignOrder(false);

        this.setUpdatedData();

      }

    },
    removeErrorFiles(){

    },
    setRemoveData(file,product){

      let index = this.draggableData.findIndex((dataItem)=> dataItem.name == file.name);

      if(index != -1){

        this.draggableData.splice(index,1);

        this.assignOrder(false);

        this.onRemove(product);

      }

    },
    handleImagePreview(file){

      this.$emit('preview-event',file);

    },
    assignOrder(setFiles){

      for(let i = 0 ; i < this.draggableData.length ; i++){

        this.draggableData[i].current_index = i;

      }

      if(setFiles){

        this.setUpdatedData();

      }

    }
  },
  mounted(){
    
    this.draggableData = _.cloneDeep(this.draggableValues);

  }
}
</script>

<style>

</style>