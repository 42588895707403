<template>
    <div class="appendedFile">
        <div class="validFile" v-if="fileObject.url">
            <img 
                src="/images/video_not_found.png" 
                :alt="fileObject.name"
                v-if="videoLoadError"
            >
            <video 
                :src="fileObject.url" 
                :alt="fileObject.name"
                @error="handleImageError"
            >
            </video>
            <span class="uploadSuccess">
                <i class="el-icon-upload-success el-icon-check"></i>
            </span>
            <div class="optionsLayer">
                <button :disabled="showProgressBar">
                    <i class="el-icon-video-play" @click="previewHandle"></i>
                </button>
                <button :disabled="showProgressBar">
                    <i class="el-icon-delete" @click="removeHandling"></i>
                </button>
            </div>
        </div>
        <div class="validFile" v-else>
            <el-progress type="circle" :percentage="progress" v-if="showProgressBar"/>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    data() {
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
            progress:0,
            showProgressBar:false,

            videoLoadError:false,
        }
    },
    props:{
        setFile:{
            type: Function
        },
        removeAsset:{
            type: Function
        },
        removeSingleFile:{
            type: Function
        },
        fileObject:{
            description: 'File object'
        },
        // productId:{
        //     description: 'selected product id'
        // },
        // indexOfFile:{
        //     description:'index of file'
        // },
        moduleType:{
            type:String,
            required: true,
            description: 'module type',
            default: 'products'
        },
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
        }),
    },
    methods:{
        async fileUploadHandle(){
            
            if(this.fileObject.type.includes("video/")){
                
                if(this.moduleType == 'products'){

                    let formData = new FormData();

                    formData.append('file',this.fileObject);

                    try{
                        const response = await this.$axios.post('/product/video/add', formData, {
                            headers: {'Content-Type': 'multipart/form-data',},
                            onUploadProgress: progressEvent => {
                                this.showProgressBar = true
                                this.progress= parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                            }
                        });
                        if(response.data.status_code == '5000'){

                            // this.$notify({
                            //     title: 'success',
                            //     type: 'Success',
                            //     message: response.data.message,
                            // });

                            this.showProgressBar = false;
                            this.progress = 0;

                            let updatedFileObject = {
                                url: this.base_image_url + this.settings.store_id + '/product/' + response.data.video,
                                name: response.data.video,
                                orgName: this.fileObject.name
                            }

                            this.setUpdatedFile(updatedFileObject);

                            this.$store.commit('settings_module/update_remaining_space',response.data.remaining_space.usage_space);

                        }
                    }catch(error){

                        this.removeSingleFile(this.fileObject);

                        if(error.response){

                            if(error.response.data.error.includes("storage limit has been reached") || 
                            error.response.data.error.includes('deleted or is no longer available')){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error,
                                });

                            }else if(error.response.data.error.includes("doesn't support")){

                                let subscriptionPath = window.location.origin + "/subscription";

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error 
                                    + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }

                }else if(this.moduleType == 'product-bundles'){

                    let formData = new FormData();

                    formData.append('file',this.fileObject);

                    try{
                        const response = await this.$axios.post('/product-bundle/video/upload', formData, {
                            headers: {'Content-Type': 'multipart/form-data',},
                            onUploadProgress: progressEvent => {
                                this.showProgressBar = true
                                this.progress= parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                            }
                        });
                        if(response.data.status_code == '5000'){

                            // this.$notify({
                            //     title: 'success',
                            //     type: 'Success',
                            //     message: response.data.message,
                            // });

                            this.showProgressBar = false;
                            this.progress = 0;

                            let updatedFileObject = {
                                url: this.base_image_url + this.settings.store_id + '/product-bundle/' + response.data.video,
                                name: response.data.video,
                                orgName: this.fileObject.name
                            }

                            this.setUpdatedFile(updatedFileObject);

                            this.$store.commit('settings_module/update_remaining_space',response.data.remaining_space.usage_space);

                        }
                    }catch(error){

                        this.removeSingleFile(this.fileObject);

                        if(error.response){

                            if(error.response.data.error.includes("storage limit has been reached") || 
                            error.response.data.error.includes('deleted or is no longer available')){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error,
                                });

                            }else if(error.response.data.error.includes("doesn't support")){

                                let subscriptionPath = window.location.origin + "/subscription";

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error 
                                    + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }

                }

            }else{
                
                this.$notify({
                    type: "error",
                    title: "Alert",
                    message: "Uploaded file must be a video.",
                }); 

            }
        },
        setUpdatedFile(updateFile){

            this.setFile(updateFile);

        },
        async removeHandling(){

            this.removeAsset(this.fileObject);

        },
        previewHandle(){

            if(this.videoLoadError){

                this.$message({
                    type: "error",
                    showClose: true,
                    message: "The video loading process encountered an error.",
                });

            }else{

                this.$emit('preview-event',this.fileObject);

            }

        },

        //image loading error handling
        handleImageError(e){

            this.videoLoadError = true;

            // e.target.src = '/images/image_not_found.png'

        },
    },
    mounted(){

        if(this.fileObject instanceof File){

            this.fileUploadHandle();
            
        }

    }
}
</script>

<style>

</style>